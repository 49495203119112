@use "breakpoints.scss";

.badges {
  display: flex;
  flex-wrap: wrap;
  width: 96%;
}

.badges li {
  margin: 4px 4px 4px 0;
}

.badges li>a,
.badges li>span {
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 1px rgba(var(--mainFgRgb), 0.035);
  border-radius: var(--borderRadius);
  padding: 4px 10px 4px;
  background: var(--gray150);
  color: var(--mainFg);
  font-size: 0.8em;
  line-height: 1;
  cursor: pointer;

  svg {
    margin-right: 4px;
    color: var(--introBoxMeta)
  }

  // &.large {
  //   font-size: 1em;
  //   padding: 6px 12px 6px 12px;
  // }
}

.inlineBadge {
  display: inline-flex;
  align-items: center;
  box-shadow: inset 0 0 0 1px rgba(var(--mainFgRgb), 0.035);
  background-color: var(--tab);
  color: var(--introBoxText);
  border-radius: var(--borderRadius);
  padding: 0 0.3em;
  height: 22px;
  line-height: 22px;
  font-size: 0.8125rem;
}

.badges li>span {
  cursor: default;
}

.badges:not(.link-color) li a {
  color: inherit;
}

.blue-box .badges li a,
.blue-box .badges li>span {
  background-color: var(--tab);
  color: var(--introBoxText);
  border-radius: var(--borderRadius);
  padding: 0 0.5em;
  height: 24px;
  line-height: 24px;
  font-size: 0.8125rem;

  svg {
    margin-right: 8px;
    color: var(--introBoxMeta)
  }

  &.large {
    font-size: 0.95em;
    padding: 0 0.6em;
    height: 26px;
    line-height: 26px;
  }
}

.appItemBox .badges li a,
.appItemBox .badges li>span {
  background: var(--gray150);
  color: var(--mainFg);
}

.badges.link-color span span {
  cursor: pointer;
  color: var(--linkColor)
}

.badges.link-color li a {
  color: var(--linkColor)
}

.badges.muted li a,
.badges.muted li span {
  background-color: var(--gray100);
  box-shadow: inset 0 0 0 1px rgba(var(--mainFgRgb), 0.045);
}

.badges li.badge-license>span {
  color: var(--positiveGreenerDark);
  background: var(--positiveGreenerLight);

  svg {
    color: var(--positiveGreener);
  }
}

.badges li.badge-license.paid>span {
  color: var(--mainFg);
  background-color: var(--gray200);
}

.badges li.badge-important>span,
.badges li.badge-important>a {
  color: var(--danger);
  background: var(--lightDanger);

  svg {
    color: var(--danger);
  }
}

.badges li.badge-mildly-important>span {
  color: var(--positiveGreenerDark);
  background: var(--positiveGreenerLight);

  svg {
    color: var(--positiveGreener);
  }
}

.badges li.badge-more-important>a,
.badges li.badge-more-important>span {
  background-color: var(--brandLight3);
  color: var(--linkColor);

}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {

  .badges li>a,
  .badges li>span {

    &.large {

      font-size: 0.95em;
      padding: 0 0.6em;
      height: 26px;
      line-height: 26px;
    }

  }
}