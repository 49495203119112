/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--introBoxText) !important;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px var(--introBoxText),
    0 0 5px var(--introBoxText);
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  bottom: 25px !important;
  right: 25px !important;
  top: auto !important;
}

.spinner-icon {
  width: 28px;
  height: 28px;
  box-sizing: border-box;

  border: solid 4px transparent;
  border-top-color: var(--introBoxText);
  border-left-color: var(--introBoxText);
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.spinner-icon-small {
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  border-width: 2px;
  border-top-color: var(--gray300);
  border-left-color: var(--gray300);
}

/* .spinner-white {
    border-top-color: var(--spinner-color-dark);
    border-left-color: var(--spinner-color-dark);
  } */

.spinner-black {
  border-top-color: var(--mainBg);
  border-left-color: var(--mainBg);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
