@use "breakpoints.scss";
@use "various.scss";

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: inherit;
}

html {
  overflow-y: scroll;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

:root {
  --fontSizeMediumPx: 14px;
  --fontSizeSmallPx: 12px;
  --minorTransition: .15s;
  --borderRadius: 5px;

  --mainBg: #ffffff;
  --mainBgRgb: 255, 255, 255;
  --mainFg: #003A5B;
  --mainFgRgb: 0, 58, 91;


  --brandLight1: #7EC5ED;
  --brandLight3: #D6ECF8;
  --brandLight4: #E0EAF4;
  --brandLight5: #E6EBF8;
  --brandLight6: #F5F9FD; // EDF6FF // #E6EBF8
  --brandLight7: #F5F9FD;
  --brandLight8: #F5F9FD;
  --brandLight9: #D4DFE9;
  --brandLight10: #fafcfd;
  --brandLight11: #EDF6FF;
  --brandLight12: #e4ecf4;



  --introBoxMeta: #5f6a7b;
  --introBoxText: #003A5B;
  --introBoxLink: #0269a2;
  --linkColor: #0269a2;
  --linkColorHeader: #0177bc;
  --popBrand: #0C9EF0;
  --headingButton: #0177bc;
  --topBrand: #027ac0;
  --topBrandRgb: 2, 122, 192;
  --topBrand10: #407da6;
  --tab: #E0EAF4;
  --mainBrand: #0C9EF0;
  --gray50: #fdfdfd;
  --gray100: #fafafa;
  --gray150: #F5F9FD;
  --gray200: #f1f1f1;
  --gray250: #ebebeb;
  --gray300: #CCD6E7;
  --gray400: #d7d7d7;
  --gray500: #8c8c8c;
  --gray700: #5E6775;
  --gray800: #003A5B;
  --gray1000: #000;
  --meta: #547282;
  --metaLight: #9aa4b4;
  --metaAlt: #5E6775;
  --negativeLight: #fff6e8;
  --positiveGreenerDark: #007f59;
  --positiveGreener: #00B780;
  --positiveGreenerLight: #E9F8F3;
  --danger: #F55C3A;
  --dangerDark: #a52e13;
  --lightDanger: #F3E0DC;
  --footer: #003A5B;

  --ctaMain: #8962F8;
  --ctaLight: #e1d5f7;
  --ctaLightest: #FBF9FF;
}

[data-theme="dark"] {

  --mainBg: #0c0f11;
  --mainBgRgb: 12, 15, 17;
  --mainFg: #e4e3e3;
  --mainFgRgb: 201, 201, 201;


  --brandLight1: #40515b;
  --brandLight3: #30383d;
  --brandLight4: #2D2F33;
  --brandLight5: #303238;
  --brandLight6: #050505;
  --brandLight7: #192125;
  --brandLight8: #f2f2f2;
  --brandLight9: #46484C;
  --brandLight10: #192125;
  --brandLight11: #192125;
  --brandLight12: #192125;


  --introBoxMeta: #ababac;
  --introBoxText: #e4e3e3;
  --introBoxLink: #f2f2f2;
  --linkColor: #e8e8e8;
  --linkColorHeader: #e8e8e8;
  --popBrand: #87aec6;
  --headingButton: #2d8bc2;
  --topBrand: #0289D5;
  --topBrand10: #407da6;
  --tab: #242527;
  --mainBrand: #083350;

  --gray50: #13161c;
  --gray100: #181b21;
  --gray150: #1d2026;
  --gray200: #22252b;
  --gray250: #272a30;
  --gray300: #2c2f35;
  --gray400: #36393f;
  --gray500: #404349;
  --gray700: #30343b;
  --gray800: #c9c9c9;
  --gray1000: #000;
  --meta: #9e9e9e;
  --metaAlt: #c9c9c9;
  --metaLight: #7b7b7b;
  --negativeLight: #1e1406;
  --positiveGreenerDark: #007f59;
  --positiveGreener: #007f59;
  --positiveGreenerLight: #001d14;
  --danger: #a52e13;
  --dangerDark: #a52e13;
  --lightDanger: #31120c;
  --footer: #083350;

  --ctaMain: #8962F8;
  --ctaLight: #27124b;
  --ctaLightest: #1a093d;
}

body {
  //font-family: "Roboto Flex rev=1"; // "Arial", "Roboto Flex", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--mainFg);
  background-color: var(--mainBg);
}

i {
  font-style: normal;
}

img {
  max-width: 100%;
}

#nprogress .bar {
  height: 6px !important;
}

button,
input[type="submit"] {
  cursor: pointer;
}

input[type="text"],
select,
textarea {
  background-color: var(--mainBg);
  outline: none;
  color: var(--mainText)
}

code {
  font-family: monospace;
}

/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {
  body {
    font-size: 16px;
    line-height: 1.5;
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0.333rem 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
  line-height: 1.2;

  letter-spacing: -0.025em;
}

pre {
  background: var(--gray150);
  border: 1px solid var(--gray250);
  color: #666;
  font-family: "Courier New", Courier, monospace;
  line-height: 1.6;
  margin: 1em 0;
  width: 90%;
  overflow: auto;
  padding: 0.5em 1em;
  display: block;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.md,
.md_Desc,
.md_Comments,
.md_News,
.md_Compact,
.md_NoBoxText {
  white-space: pre-line;
  word-break: break-word;

  h1,
  h2,
  h3 {
    margin: 22px 0 -22px 0;

  }

  hr {
    margin: 16px 0 -16px 0;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }
}

.md_Comments,
.md_NoBoxText {

  margin: 4px 0 4px 0;

  ul,
  ol {
    list-style-type: disc;
    margin-top: -32px;
    margin-bottom: 12px;
    padding-inline-start: 32px;
    display: block;

    li {
      margin: 0 0 -20px 0;
      white-space: normal;

      p {
        padding: 0;
        display: inline-block;
      }
    }
  }

  ul:first-child {
    margin-top: -12px;
  }

  ol {
    list-style-type: decimal;
  }
}

.md_NoBoxText {
  margin: 2px 0 6px 0;
}

.md_Compact {
  white-space: normal;

  h1,
  h2,
  h3 {
    margin: 22px 0 2px 0;

  }
}

.md_Desc {

  ul,
  ol {
    list-style-type: "- ";
    margin-bottom: 0px;
    padding-inline-start: 12px;
    display: block;
    white-space: normal;

    li {

      margin-top: 2px;

      p {
        padding: 0;
        display: block;
        line-height: 22px;
      }
    }
  }
}

.md_Desc,
.md_News {

  p {
    display: inline-block;
    padding: 8px 0;
    white-space: pre-line;
  }

  ol {
    list-style-type: decimal;
    padding-inline-start: 22px;
  }

}

.md_News {
  font-size: 120%;

  code {
    border: 1px solid var(--gray250);
    background-color: var(--gray150);
    color: var(--dangerDark);
    font-variant-ligatures: none;
    white-space: pre;
    word-wrap: break-word;
    word-break: normal;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 0.8em;
    font-family: Monaco, Menlo, Consolas, Courier New, monospace !important;
  }

  ul,
  ol {
    list-style-type: "- ";
    margin-bottom: 0;
    padding-inline-start: 12px;
    display: block;
    white-space: normal;

    li {
      p {
        padding: 0;
        display: block;
      }
    }
  }

  a {
    text-decoration: underline;
  }
}



.container {
  --bs-gutter-x: 1.5rem;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-right: auto;
  margin-left: auto;
}


.main-wrapper {
  color: var(--mainFg);
  background-color: var(--mainBg);
  padding-bottom: 3em;
}

// /* Applies to larger screens (+992px) */
// @media screen and (breakpoints.$lgUp) {


//   .container {
//     max-width: 992px;
//   }

//   .wide-container {
//     max-width: 982px;
//   }
// }

/* Applies to larger screens (+1180px) */
@media screen and (breakpoints.$xlUp) {
  .wide-container {
    max-width: 1220px;
  }

  .container {
    max-width: 1200px;
  }
}

/* Applies to largest screens (+1400px) */
@media screen and (breakpoints.$xxlUp) {
  .wide-container {
    max-width: 1402px;
  }
}

/* Applies to mobiles (-768px) */
@media screen and (breakpoints.$xlDown) {
  .wide-container {
    padding: 0;
  }
}

/* Default Layout */

.skip-link {
  display: block;
  padding: 1em;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: black;
  color: white;
  z-index: 1000;
  transform: translateX(-100000px);
}

.skip-link:focus,
.skip-link:active {
  transform: translateX(0px);
}

/* End Default Layout */

.meta,
.meta-medium,
.meta-relative {
  color: var(--meta);
}


.meta {
  font-size: var(--fontSizeMediumPx);
}

.meta-medium {
  font-size: var(--fontSizeSmallPx);
}

.meta-relative {
  font-size: 0.55em;
  font-weight: normal;
}

.cta-text {
  color: var(--ctaMain) !important;
}

.block-modal {
  width: 90vw;
  height: 90vh;
  position: absolute;
  background: rgb(var(--mainBgRgb));
  overflow: auto;
  outline: none;
  padding: 20px;
}

// .appIcon {
//   border-radius: 12px;
// }

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
  .block-modal {
    width: 600px;
    height: auto;
    max-height: 80vh;
  }

  .block-modal-lg {
    width: 50vw;
    height: auto;
    max-height: 80vw;
  }

  // .appIcon {
  //   border-radius: 20px;
  // }

  .meta {
    font-size: 0.875em;
  }
}

.bold {
  font-weight: 500;
}

strong {
  font-weight: 500;
}

// .main-wrapper {
//   display: flex;
//   flex-direction: column;
// }

// .wrapper .meta {
//   color: var(--meta);
// }

// .wrapper .relative-meta {
//   color: var(--meta);
// }

a {
  color: var(--linkColor);
  text-decoration: none;
}

.fake-a {
  color: var(--linkColor);
  text-decoration: none;
  cursor: pointer;
}

.fake-a-alt {
  text-decoration: underline;
  cursor: pointer;
}

a.meta {
  text-decoration: underline;
}

a.no-link-color {
  color: var(--mainFg) !important;
}

.meta a.no-link-color {
  color: var(--meta);
}

a.alt-link {
  color: var(--mainFg);
  text-decoration: underline;
}

.wrapper .meta a.no-link-color {
  color: var(--meta);
  text-decoration: none;
}

.blue-box {

  a {
    color: var(--introBoxLink);
  }

  a.underline {
    color: var(--introBoxLink);
    text-decoration: underline;
  }

  a.no-link-color {
    color: var(--introBoxText) !important;
  }

  .intro-text a {
    color: var(--introBoxLink);
    text-decoration: underline;
    font-size: 18px;
  }

  .meta {
    color: var(--introBoxMeta);
  }
}

.bannerLink {
  a {
    color: var(--mainFg) !important;
    text-decoration: none;
  }
}

hr.subtle {
  border: 0;
  background: var(--gray200);
  height: 1px;
}

hr.wide {
  height: 2px;
}

mark {
  background: #a6f5ed;
}

/* Sidebar layout */

.sidebar-layout {
  display: flex;
  flex-direction: column;
}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
  .sidebar-layout {
    display: block;
  }

  .sidebar-layout:after,
  .sidebar-layout:before {
    clear: both;
    content: " ";
    display: table;
  }


}

.text-center {
  text-align: center !important;
}

.f-space-between {
  display: flex;
  justify-content: space-between;
}

.f-end {
  display: flex;
  justify-content: flex-end;
}

.f-h-center {
  display: flex;
  align-items: center;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.showMoreButton {
  white-space: nowrap;
  cursor: pointer;
  padding: 2px 4px;
  font-size: 0.8em;
  margin-left: 0.25em;
  color: var(--metaLight);
  border-radius: var(--borderRadius);


  :before {
    position: absolute;
    left: -47px;
    content: '';
    height: 100%;
    width: 50px;
    background: linear-gradient(to left, white, transparent);
  }
}

.blue-box {
  .showMoreButton {
    color: var(--meta);
    box-shadow: none;
    position: relative;
    bottom: 2px;

    :before {
      background: transparent;
    }
  }
}

.commonBoxList {
  background: var(--gray50);
  border-radius: var(--borderRadius);
  border: 1px solid var(--gray200);
}

.modernBox {
  background: var(--brandLight6);
  border-radius: var(--borderRadius);
  border: 1px solid var(--tab);
}

/* Server rendered content */

.server-content {
  word-break: break-word;

  .mini-app-icon,
  .icon {
    height: 12px;
    width: 12px;
    vertical-align: middle;
    margin-right: 3px;
    margin-left: 0.25em;
    position: relative;
    top: -0.115em;
  }

  em {
    font-style: italic;
  }
}


/* React Image Lightbox */

.ril__caption {
  justify-content: center !important;
}

.server-content.extraPadding p:not(:first-child) {
  margin: 1.4em 0 0 0 !important;
}

.server-content.longform {
  white-space: pre-wrap;
}

.server-content.longform p {
  margin: 0.4em 0;
}

.server-content.longform a {
  text-decoration: none;
}

.server-content.formatHtml ul li {
  list-style: disc;
  margin-left: 20px;
}

.Positive {
  color: var(--positiveGreener);
  font-weight: 500;
}

.Negative {
  color: var(--danger);
  font-weight: 500;
}

.VeryNegative {
  color: red;
  font-weight: 500;
}

//* https://stackoverflow.com/questions/69210366/css-line-clamp-not-working-in-safari-on-mac *//

.md {
  p {
    display: inline;
  }
}



.maxLine3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.maxLine2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Transition Globals

.transitionDefault {

  transition: visibility 0s, opacity var(--minorTransition) linear, color var(--minorTransition) linear, background-color var(--minorTransition) linear, transform var(--minorTransition) ease-in-out;
}

.spacer-1 {
  height: 6px;
}

.spacer-2 {
  height: 12px;
}

.spacer-3 {
  height: 18px;
}

.spacer-4 {
  height: 24px;
}

.spacer-5 {
  height: 30px;
}

.spacer-6 {
  height: 36px;
}

.spacer-8 {
  height: 48px;
}

.spacer-12 {
  height: 62px;
}

.spacer-16 {
  height: 76px;
}

/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {

  .spacer-12 {
    height: 72px;
  }

  .spacer-16 {
    height: 96px;
  }
}